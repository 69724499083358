@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-gradient-primary {
    background: linear-gradient(to right, #FFB033, #FFCC33);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}