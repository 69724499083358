.App {
  text-align: center;
}


/*Auto Fill Colors!*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #FFFFFF !important; /* Change text color to white */
  background-color: #1f2937 !important; /* Change background color to dark grey */
}

.glowing-divider {
  position: relative;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #030712, #32FFB7, #030712);
  box-shadow: 0 0 15px rgba(50, 255, 183, 0.8);
  margin: 2rem 0;
  animation: glow 2s infinite alternate;
}

.glowing-border {
  position: relative;
}

.glowing-border::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, #030712, #32FFB7, #030712);
  box-shadow: 0 0 15px rgba(50, 255, 183, 0.8);
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px rgba(50, 255, 183, 0.8);
  }
  to {
    box-shadow: 0 0 15px rgba(50, 255, 183, 0.8);
  }
}

.text-glow-model-menu {
  text-shadow: 0 0 5px rgba(255, 176, 51, 0.5), 0 0 10px rgba(255, 204, 51, 0.5);
}


